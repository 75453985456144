// @flow

import React from 'react';
import cn from 'classnames';

import { withTheme } from 'providers/Theme.provider';

import type { InputFieldData } from '../types/inputField';

import CN from './PasswordInputField.module.scss';

type Props = {
  data: InputFieldData,
  allowAutocomplete: boolean,
  onChangeHandler?: (value: string, id: string) => void,
  onBlurHandler?: (value: string, id: string) => void,
  validateChange?: (value: string) => boolean,
  validateBlur?: (value: string) => string,
};

type State = {
  type: string,
  inputValue: string,
  errorMessage: string | null,
  touched: boolean,
};

export class PasswordInputField extends React.Component<Props, State> {
  static defaultProps = {
    onChangeHandler: () => {},
    onBlurHandler: () => {},
    validateChange: () => true,
    validateBlur: () => '',
  };
  constructor(props: Props) {
    super(props);

    this.state = {
      type: props.data.type,
      inputValue: this.props.data.inputValue,
      errorMessage: '',
      touched: false,
    };
  }
  onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;
    const { id } = target;
    if (this.props.validateChange && !this.props.validateChange(value)) {
      return;
    }
    this.setState({ inputValue: value }, () => {
      if (this.props.onChangeHandler) {
        this.props.onChangeHandler(value, id);
      }
    });
    if (this.props.validateBlur && this.state.touched) {
      const errorMessage = this.props.validateBlur(value);
      this.setState({ errorMessage });
    }
  };
  onBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;
    const { id } = target;
    if (this.props.validateBlur) {
      const errorMessage = this.props.validateBlur(value);
      this.setState({ errorMessage });
    }
    if (this.props.onBlurHandler) {
      this.props.onBlurHandler(value, id);
    }
    if (!this.state.touched) {
      this.setState({ touched: true });
    }
  };
  showHidePassword = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  render() {
    const { errorMessage } = this.state;
    const { isDarkMode, isNeutralMode } = this.props;

    const passwordClasses = cn({
      [CN.input]: true,
      [CN.hasError]: errorMessage,
    });

    return (
      <div
        data-bem="PasswordInputField"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.inputContainer}>
          <label htmlFor="password" className={CN.inputLabel}>
            {this.props.label}
          </label>
          <div className={CN.passwordContainer}>
            <input
              className={passwordClasses}
              id={this.props.data.id}
              type={this.state.type}
              placeholder={this.props.data.placeholder}
              value={this.state.inputValue}
              onChange={this.onChange}
              onBlur={this.onBlur}
              autoComplete={this.props.allowAutocomplete ? '' : 'new-password'}
            />
            <div className={CN.eyeContainer} role="button" tabIndex="0" onClick={this.showHidePassword}>
              {this.state.type === 'password' ? <div className={CN.eyeOpen} /> : <div className={CN.eyeClose} />}
            </div>
          </div>
        </div>

        {errorMessage && <span className={CN.error}>{errorMessage}</span>}
      </div>
    );
  }
}
export default withTheme()(PasswordInputField);
