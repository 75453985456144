// @flow
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react/no-danger */
/* eslint-disable object-curly-newline */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable comma-dangle */
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import DisclaimerText from 'invite/components/DisclaimerText.component';
import moment from 'moment';

import { clearBillingInfo, clearIntentClientSecret, CreateToken, setupIntent } from 'actions/billing';
import Button from 'components/Button.component';
import FoldOut from 'components/FoldOut.component';
import { withTheme } from 'providers/Theme.provider';

import currency from 'booking/common/currency.util';
import HeaderComponent from 'booking/common/Header';
import { getBookingCostFunc } from 'lib/utils';
import type { SalonDetails } from 'types/salonDetails';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylist';

import {
  clearUserCardDetails,
  getClientInvites,
  resetPaymentIntentId,
  setAddCardError,
  setPageVisited,
} from '../actions';

import SetupFormWrap from './SetupFormWrap';

import CN from './AddNewCardWrap.module.scss';

const DISPLAY_NCOB_TEXT = false;

type State = {
  errorMessage: string,
  paymentError: boolean,
  getCostDone: boolean,
};
type StateProps = {
  salon: any,
  billing: any,
  cognito: any,
  booking: any,
  errorMessage: '',
  needs_card: boolean,
  no_show_protection: boolean,
  ncob_deposit_settings: string,
  salon_ncob_text: string,
  salonDetails: SalonDetails,
  selectedServices: Service[],
  selectedPatchTime: moment | null,
  selectedAppointmentTime: moment,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  bookingResponse: any,
  errorMessage: any,
  totalPrice: number,
  notes: string,
  username: string,
  cardDetails: {
    last4: string,
    exp_month: string,
    exp_year: string,
  },
  CreateToken: (token: any) => void,
  clearBillingInfo: () => void,
  setPageVisited: (page: string) => void,
  setAddCardError: (error: string) => void,
  stripeToken: any,
  clearUserCardDetails: () => void,
  clearIntentClientSecret: () => void,
  chargeClientSecret: (
    salonId: number,
    appointmentDate: moment,
    services: number[],
    stylists: number[],
    username: string,
  ) => void,
  setupIntent: (salonId: number, username: string) => void,
  resetPaymentIntentId: () => void,
  getClientInvites: (cognitoUsername: string) => void,
  errorMessage: string,
  phone_number: string,
  email: string,
  isFetching: boolean,
};
type OwnProps = {
  history: any,
  match: {
    params: { id: number },
    url: string,
  },
  public_key: string,
  bookingError: any,
};
type Props = StateProps & OwnProps;

export class AddNewCardWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: '',
      paymentError: false,
      getCostDone: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.billing.appointment_cost && !this.state.getCostDone) {
      if (nextProps.history.location.state && nextProps.history.location.state.from !== 'invite') {
        this.setState({ getCostDone: true });
        getBookingCostFunc();
      }
    }
  }

  setToken = (token: any) => {
    this.props.CreateToken(token);
  };
  setPaymentError = (trueOrFalse: boolean) => {
    this.setState({ paymentError: trueOrFalse });
  };
  isEmpty = (obj: any) => {
    const keys = Object.keys(obj);

    if (keys.length > 0) {
      return false;
    }
    return true;
  };

  hideError = () => {
    this.setState({ errorMessage: '' });
  };
  onAppleRequestBtnClick = () => {
    //
  };
  startAgain = () => {
    this.props.history.push(`/salon/${this.props.match.params.id}`);
  };
  render() {
    const { salonDetails, isDarkMode, isNeutralMode } = this.props;

    const abbreviation = salonDetails.currency;
    let { username } = this.props;
    if (!username) {
      if (this.props.cognito && Object.keys(this.props.cognito.attributes).length) {
        [username] = this.props.cognito.attributes.name.split(' ');
      }
    }
    let appointmentCost = this.props.billing.appointment_cost;
    let payToday = this.props.billing.pay_today;
    const locationState = this.props.history.location.state;
    if (locationState && locationState.from === 'invite') {
      appointmentCost = locationState.appointmentPrice;
      payToday = locationState.payFullAmount ? appointmentCost : locationState.requested_deposit;
    }
    let welcomeMessage;

    welcomeMessage = (
      <div>
        <div className={CN.title}>Please enter your card details.</div>
        <div className={CN.small}>Then confirm your booking on the next page.</div>
      </div>
    );

    if (this.state.paymentError) {
      welcomeMessage = (
        <div>
          <div className={CN.title}>Card setup failed</div>
          <div className={CN.small}> Please try again by clicking the Update my card details button</div>
        </div>
      );
    }
    let anyError;
    let bookingError;
    if (this.state.errorMessage) {
      anyError = 'We are having problems creating your booking, please try again.';
    }
    if (this.props.bookingError) {
      bookingError =
        'Sorry, there’s been a problem and your booking has not been created. The time slot you chose may have been taken or you might be on an old version, so please close this tab, open a new tab and try again';
    }
    return (
      <div
        data-bem="AddNewCardWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          <HeaderComponent history={this.props.history} />
          {welcomeMessage}

          {bookingError && <span className={CN.error}>{bookingError}</span>}
          {bookingError && (
            <Button variant="primary" width="fixed" onClick={this.startAgain}>
              START AGAIN
            </Button>
          )}
          {!bookingError && (
            <div>
              <div>
                <div className={CN.bookingValue}>
                  <span>Total:</span>
                  <span className={CN.total}>{currency({ value: appointmentCost, abbreviation })}</span>
                </div>
                <div className={CN.bookingValue}>
                  <span>of which payable today:</span>
                  <span className={CN.totalToday}>{currency({ value: payToday, abbreviation })}</span>
                </div>
                {DISPLAY_NCOB_TEXT && (
                  <div>
                    {!this.state.paymentError && this.props.no_show_protection && (
                      <div className={CN.bold}>
                        Card details are required to confirm your booking but you will NOT be charged today. Payment
                        will only be taken after your appointment is complete. We reserve the right to charge a
                        cancellation or no-show fee as per our cancellation policy below.
                      </div>
                    )}
                    {!this.state.paymentError && this.props.ncob_deposit_settings !== 'null' && (
                      <div className={CN.bold}>
                        To secure your booking we require an upfront payment which we reserve the right to keep if you
                        fail to show for your appointment or cancel last minutes. Cancellations with advanced notice may
                        be refundable in line with our cancellation policy.
                      </div>
                    )}
                  </div>
                )}
              </div>
              <SetupFormWrap history={this.props.history} salonId={this.props.match.params.id} />

              <DisclaimerText />

              {!this.props.isWaitList && (
                <div className={CN.policyContainer} data-bem="AddNewCardWrap__foldOut">
                  <FoldOut title="Cancellation Policy" icon="policy">
                    <div className={CN.policyText} data-bem="AddNewCardWrap__policy--text">
                      {this.props.salon_ncob_text}
                    </div>
                  </FoldOut>
                </div>
              )}
              {this.props.bookingError.indexOf('Not found') < 0 && <div className={CN.error}>{anyError}</div>}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state: any): any => ({
  salonDetails: state.booking.salonDetails,
  selectedServices: state.booking.selectedServices,
  selectedPatchTime: state.booking.selectedPatchTime,
  selectedAppointmentTime: state.booking.selectedAppointmentTime,
  notes: state.booking.notes,
  totalPrice: state.booking.totalPrice,
  selectedStylists: state.booking.selectedStylists,
  bookingResponse: state.booking.bookingResponse,
  bookingError: state.booking.errorMessage,
  isFetching: state.booking.isFetching,
  username: state.billing.username,
  booking: state.booking,
  cardDetails: state.booking.cardDetails,
  stripeToken: Object.keys(state.billing.stripeToken).length ? state.billing.stripeToken : {},
  cognito: state.cognito,
  billing: state.billing,
  ncob_deposit_settings: state.booking.salonDetails.ncob_deposit_settings,
  no_show_protection: state.booking.salonDetails.no_show_protection,
  needs_card: state.booking.salonDetails.needs_card,
  salon_ncob_text: state.booking.salonDetails.salon_ncob_text,
});

export function mapDispatchToProps(dispatch: any) {
  return {
    CreateToken(token: any) {
      dispatch(CreateToken(token));
    },
    clearUserCardDetails() {
      dispatch(clearUserCardDetails());
    },
    clearBillingInfo() {
      dispatch(clearBillingInfo());
    },
    setPageVisited(page: string) {
      dispatch(setPageVisited(page));
    },
    setupIntent(salonId: number, username: string) {
      dispatch(setupIntent(salonId, username));
    },
    resetPaymentIntentId() {
      dispatch(resetPaymentIntentId());
    },
    clearIntentClientSecret() {
      dispatch(clearIntentClientSecret());
    },
    getClientInvites(cognitoUsername: string) {
      dispatch(getClientInvites(cognitoUsername));
    },
    setAddCardError(errorMessage: string) {
      dispatch(setAddCardError(errorMessage));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(AddNewCardWrap));
