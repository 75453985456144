import getAnyOrNull from 'utils/accessors/getAnyOrNull.util';
import nullBind from 'utils/functions/nullBind.util';

export const selectCurrentSalon = nullBind(getAnyOrNull, 'booking.salonDetails');

export const selectCurrentSalonId = nullBind(getAnyOrNull, 'booking.salonDetails.id');
export const selectCurrentSalonName = nullBind(getAnyOrNull, 'booking.salonDetails.name');

export const selectCurrentSalonEmail = nullBind(getAnyOrNull, 'booking.salonDetails.email');
export const selectCurrentSalonPhone = nullBind(getAnyOrNull, 'booking.salonDetails.phone');
export const selectCurrentSalonAddressLine1 = nullBind(getAnyOrNull, 'booking.salonDetails.address_line_1');
export const selectCurrentSalonCity = nullBind(getAnyOrNull, 'booking.salonDetails.city');
export const selectCurrentSalonPostCode = nullBind(getAnyOrNull, 'booking.salonDetails.postcode');

export const selectCurrentSalonNcobMode = nullBind(getAnyOrNull, 'booking.salonDetails.ncob_mode');

export const selectCurrentSalonCanTakeVoucher = nullBind(
  getAnyOrNull,
  'booking.salonDetails.can_take_voucher_payments',
);
export const selectCurrentSalonStripeVoucherLink = nullBind(getAnyOrNull, 'booking.salonDetails.stripe_voucher_link');
export const selectCurrentSalonNoShowProtection = nullBind(getAnyOrNull, 'booking.salonDetails.no_show_protection');
export const selectCurrentSalonNcobDepositSettings = nullBind(
  getAnyOrNull,
  'booking.salonDetails.ncob_deposit_settings',
);

// salonDetails data

// {
//   "address_line_1": "80 St Pancras Way",
//   "address_line_2": "",
//   "book_ncob_with_same": false,
//   "can_take_voucher_payments": false,
//   "check_ncob_availability_until": 21,
//   "city": "London",
//   "country": {
//     "code": "GB",
//     "name": "United Kingdom"
//   },
//   "currency": "GBP",
//   "email": "info@thehairshed.com",
//   "fb_details": {},
//   "group": 2,
//   "has_waitlist": false,
//   "id": 12259,
//   "image": "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAs",
//   "name": "Steph Salon",
//   "ncob_custom_amount": 0,
//   "ncob_deposit_settings": null,
//   "ncob_mode": "slick",
//   "ncob_patch_test_copy": "This is where you would write your custom patch test policy, ...",
//   "ncob_stylists_count": 3,
//   "ncob_tiny_url": "http://tinyurl.com/r2mz6g3",
//   "ncob_welcome_message": "Please select services in the order you want them. ...e",
//   "needs_card": true,
//   "no_show_protection": true,
//   "online_booking": true,
//   "phone": "+447875332200",
//   "postcode": "NW1 9DN",
//   "salon_ncob_text": "Salon - 12259 - As a small salon we hope ....",
//   "skip_today": false,
//   "stripe_voucher_link": "https://buy.stripe.com/test_aEU6oqbbW0vPe1a4gh",
//   "timezone": "Europe/London"
// }
